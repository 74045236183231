<template>
  <div>
    <company-form
      :visible="showForm"
      :resource-id="resourceId"
      :company-id="companyId"
      :status-options="statusOptions"
      @saved="getInitialData()"
      @close="onCloseForm"
    />
    <company-contacts-modal
      :company-contacts-id="companyContactsId"
      :company-uuid="companyUuid"
    />
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="height: 100%"
      class="d-flex flex-column"
    >
      <div class="card resource-view__scrollable">
        <div class="card-body">
          <div class="mb-2">
            <button
              id="create-button"
              class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary"
              :class="{
                'icon-disabled': $can('Update', 'CRM') === false,
              }"
              @click="$can('Update', 'CRM') && create()"
            >
              <feather-icon
                icon="UserPlusIcon"
                class="mr-50"
              />
              <span>{{ $t('Add Company') }}</span>
            </button>
            <b-tooltip target="create-button">
              {{ $can('Update', 'CRM') === true ? $t('Add Company') : $t('You dont have permission to add company') }}
            </b-tooltip>
          </div>
          <b-table
            ref="refTable"
            class="table-responsive"
            :busy="loading"
            :items="items"
            :is-customizable="true"
            :fields="tableColumns"
            no-local-sorting
            responsive
            primary-key="id"
            show-empty
            :empty-text="$t('No matching records found')"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <template #head()="{ label }">
              <span style="white-space: nowrap">{{ $t(label) }}</span>
            </template>
            <template #cell(companyName)="{ item }">
              <prozess-resource-name
                :has-permission="$can('Read', 'CRM')"
                style="width: 260px"
                :to="{ name: 'company-view', params: { id: item.company.uuid, tab: GenericTabs.OVERVIEW_360 } }"
                :title="`${item.company.name || ''}`"
              />
            </template>
            <template #cell(role)="{ item }">
              <b-badge
                v-if="item.role"
                variant="primary"
              >
                {{ item.role }}
              </b-badge>
            </template>
            <template #cell()="{ item, field }">
              <span
                style="max-width: 200px"
                class="d-block"
                :class="{ 'text-primary': field.key.includes('mobile'), 'text-truncate': field.type === 'LONGTEXT' }"
              >
                {{ findValue(mapValue(item, field)) }}
                <!-- {{ item.relationshipId }} -->
              </span>
            </template>
            <!-- START: Actions Column -->
            <template #cell(actions)="{ item }">
              <div class="text-nowrap">
                <feather-icon
                  :id="`edit-row-${item.relationshipId}-preview-icon`"
                  icon="EditIcon"
                  size="16"
                  class="mx-1 cursor-pointer"
                  :class="{
                    'icon-disabled': $can('Update', 'CRM') === false,
                  }"
                  @click="$can('Update', 'CRM') ? edit(item) : null"
                />
                <b-tooltip
                  :title="$can('Update', 'CRM') === true ? $t('Edit') : $t('You dont have permission to update contact')"
                  :target="`edit-row-${item.relationshipId}-preview-icon`"
                  placement="left"
                />
                <feather-icon
                  :id="`delete-row-${item.relationshipId}-preview-icon`"
                  icon="TrashIcon"
                  size="16"
                  class="mx-1 cursor-pointer"
                  :class="{
                    'icon-disabled': $can('Update', 'CRM') === false,
                  }"
                  @click="remove(item.relationshipId)"
                />
                <b-tooltip
                  :title="$can('Update', 'CRM') === true ? $t('Remove') : $t('You dont have permission to remove contact')"
                  :target="`delete-row-${item.relationshipId}-preview-icon`"
                  placement="left"
                />
              </div>
            </template>
            <!-- END: Actions Column -->
          </b-table>
          <prozess-pagination
            v-if="!loading"
            class="mx-2 mb-2 mt-2"
            :class="{ 'd-none': !total }"
            :meta="dataMeta"
            :page="currentPage"
            :total="total"
            :size="perPage"
            @change="handlePageChange"
          />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { GenericTabs } from '@/constants/app'
import { swalConfirmDeleteOption } from '@/helpers/app'
import contactService from '@/services/contact'
import companyContactService from '@/services/companyContact'
import CompanyForm from '@/components/Contact/ContactCompanyForm.vue'
import CompanyContactsModal from '@/views/pages/Contacts/CompanyContactsModal.vue'
import listMixinFactory from '@/mixins/list'
import advancedFieldMgmtService from '@/services/advancedFieldMgmt'
import crmRelationshipMappingService from '@/services/crmRelationshipMapping'

export default {
  components: {
    CompanyForm,
    CompanyContactsModal,
  },
  mixins: [
    listMixinFactory({
      routeName: 'company',
      service: companyContactService,
    }),
  ],
  props: {
    statusOptions: {
      type: Array,
      default: () => [],
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      GenericTabs,
      resourceId: null,
      companyId: null,
      showForm: false,
      loading: false,
      varKontaktNames: [],
      items: [],
      customFields: [],
      standardFields: [],
      currentPage: 1,
      total: 10,
      perPage: 25,
      companyContactsId: 'company-contacts-list',
      companyUuid: null,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.items.length

      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.total,
      }
    },
  },
  watch: {
    '$route.params': {
      handler(params) {
        if (this.service && params.tab === 'companies') {
          this.getInitialData()
        }
      },
    },
  },
  created() {
    this.service = companyContactService(this.$route.params.id, 'contact')
    this.getInitialData()
  },
  methods: {
    async getEntityOrder() {
      const { response } = await this.$async(advancedFieldMgmtService.getorder(
        { schema: 'crm',
          table: 'company_has_contact',
        }))

      const keyOrder = response.data
        if (keyOrder.length > 0) {
          let sortedTableColumns = JSON.parse(JSON.stringify(this.tableColumns))
          const firstCol = sortedTableColumns.shift()
          const lastCol = sortedTableColumns.pop()
          if (firstCol === 'companyName') {
            sortedTableColumns.unshift(firstCol)
          }
          if (lastCol === 'actions') {
            sortedTableColumns.push(lastCol)
          }
          const unsortedJson = {}
          sortedTableColumns.forEach(field => { unsortedJson[field.key] = field })
          const sortedList = []
          keyOrder.forEach(fieldKey => {
            if (unsortedJson[fieldKey] != null) {
              sortedList.push(unsortedJson[fieldKey])
            }
          })
          sortedTableColumns = JSON.parse(JSON.stringify(sortedList))

          if (firstCol != null) {
            sortedTableColumns.unshift(firstCol)
          }
          if (lastCol != null) {
            sortedTableColumns.push(lastCol)
          }
          this.tableColumns = sortedTableColumns
      }
    },
    mapItem(data) {
      const itemMapped = {}
      data.customFieldData.forEach(i => {
        itemMapped[i.key.replace(/_./g, x => x[1].toUpperCase())] = i.value
      })
      itemMapped.varKontakt = this.varKontaktNames[data.company.uuid]
      return itemMapped
    },
    mapValue(data, fieldx) {
      const retVal = {
        field: fieldx,
        item: this.mapItem(data),
      }
      return retVal
    },
    edit(item) {
      this.resourceId = item.relationshipId
      this.companyId = item.company.uuid
      this.showForm = true
    },
    async getInitialData() {
      const { page } = this.$route.query
      this.currentPage = page || 1

      const query = {
        page: page ? page - 1 : 0,
        size: this.perPage,
      }

      try {
        this.loading = true
        const res = await Promise.all([contactService.getCompanies(this.$route.params.id, query), companyContactService().getCustomFields(), this.service.getAdvanceFields()])
        this.customFields = res[1].data.sort((a, b) => b.favorite - a.favorite)
        this.standardFields = res[2].data.filter(d => d.id == null)
        this.tableColumns = []
        this.tableColumns.push({ key: 'companyName', label: 'Name', sortable: false })
        this.standardFields.forEach(sf => {
          if (sf.required || !sf.hidden) {
            this.tableColumns.push({
              ...sf,
              sortable: false,
            })
          }
         })

        this.customFields.forEach(cf => {
          if (cf.required || !cf.hidden) {
            this.tableColumns.push({
              ...cf,
              sortable: false,

            })
          }
        })
        this.tableColumns.push({ key: 'actions', class: 'text-center disabled' })

        await this.getEntityOrder()
        this.tableColumns.splice(1, 0, {
          key: 'varKontakt',
          label: 'Var Kontakt',
          class: 'disabled',
          sortable: true,
        })
        this.total = res[0].data.filteredItemsCount
        this.items = res[0].data.pageItems.reduce((acc, item) => {
          acc.push(item)
          return acc
        }, [])

        const varKontaktReq = {
          companyIds: [],
        }
        this.items.forEach(mapping => {
          varKontaktReq.companyIds.push(mapping.company.uuid)
        })
        const ress = await this.$async(crmRelationshipMappingService.getVarKontakt(varKontaktReq))
        this.varKontaktNames = ress.response.data
      } finally {
        this.loading = false
      }
    },
    collapse(item) {
      this.$set(item, 'visible', !item.visible)
    },
    create() {
      this.showForm = !this.showForm
    },
    handlePageChange(page) {
      if (!page || page === parseInt(this.$route.query.page)) {
        return
      }

      this.$router.push({
        name: this.$route.name,
        params: { tab: this.$route.params.tab },
        query: { ...this.$route.query, page },
      })
    },
    onCloseForm() {
      this.resourceId = null
      this.companyId = null
      this.showForm = false
    },
    remove(item) {
      if (this.$can('Delete', 'CRM') === false) return
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(result => {
        if (result.value) {
          this.service.removeRelation(item.company.uuid, item.relationshipId).then(() => {
            this.getInitialData()
          })
        }
      })
    },
    handleContactsModal(uuid) {
      this.companyUuid = uuid
      this.$bvModal.show(this.companyContactsId)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/company-contact-list.scss';
</style>
